@font-face {
    font-family: 'PF DinDisplay Pro';
    src: url('PFDinDisplayPro-Bold.woff2') format('woff2'),
        url('PFDinDisplayPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: url('PFDinDisplayPro-Black.woff2') format('woff2'),
        url('PFDinDisplayPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: url('PFDinDisplayPro-Medium.woff2') format('woff2'),
        url('PFDinDisplayPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: url('PFDinDisplayPro-Light.woff2') format('woff2'),
        url('PFDinDisplayPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: url('PFDinDisplayPro-Regular.woff2') format('woff2'),
        url('PFDinDisplayPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PF DinDisplay Pro ExtraThin';
    src: url('PFDinDisplayPro-ExtraThin.woff2') format('woff2'),
        url('PFDinDisplayPro-ExtraThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: url('PFDinDisplayPro-Thin.woff2') format('woff2'),
        url('PFDinDisplayPro-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

